import React from "react";
import { Box, Text } from "@chakra-ui/react";
import "../../Styles/HowToReachContent/PlaneContent.css";
import { PLANE, PLANE_DESCRIPTION } from "../../Constant/String";

const PlaneContent = () => {
  // Renamed component to PLANEContent
  return (
    <div className="background__color__plane">
      <Box className="planeBox">
        <Box className="planeBox__Content">
          <Text className="planeBox__ContentText">{PLANE}</Text>
        </Box>
        <Box className="planeBox__Description">
          <Text className="planeBox__DescriptionText">{PLANE_DESCRIPTION}</Text>
        </Box>
      </Box>
    </div>
  );
};

export default PlaneContent;
