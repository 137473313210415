import React, { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Image,
  Box,
} from "@chakra-ui/react";
import "../../Styles/Schedule.css";
import qrPDFLINKS from "../../asset/Jaisalmer_Desert_Mahotsav.pdf";
import whatsapp from "../../Images/whatsapp.png";

import QRCode from "qrcode.react";
import { sharepdflink } from "../../Static/Schedule.data";

import { WhatsappShareButton } from "react-share";
import {
  WHATSAPP_MESSAGE_FILE_SHARING,
  WHATSAPP_MESSAGE_FILE_SHARING_SEPERATOR,
  color_Selected,
} from "../../Constant/String";
const qRLinks = qrPDFLINKS;

var currentURL = window.location.href;

// Check if the URL ends with a slash
if (currentURL.endsWith("/")) {
  // Remove the last character (which is the slash)
  currentURL = currentURL.slice(0, -1);
}
const qrLinksCode = `${currentURL}${qRLinks}`;
console.log("qrLinksCode", qrLinksCode);
console.log("qrLinksCode", window.location.href);
function ShareSchedule() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shareLink = sharepdflink;
  const qrCodeRef = useRef(null);

  const handleWhatsAppShare = () => {
    const message = `${qrLinksCode}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappShareURL = `https://api.whatsapp.com/send?text=${encodedMessage}`;

    window.open(whatsappShareURL, "_blank");
  };
  //Handle Download PDF;
  const handleDownload = () => {
    window.open(`${qrLinksCode}`, "_blank");
  };
  useEffect(() => {
    if (qrCodeRef.current) {
      // Generate QR code when the component mounts
      qrCodeRef.current.makeCode(qrLinksCode);
    }
  }, [shareLink]);

  return (
    <>
      <Button
        onClick={onOpen}
        className="share_schedule_box"
        boxShadow={"2xl"}
        borderRadius={"30px"}
        style={{
          backgroundColor: color_Selected,
        }}
      >
        <span
          style={{ marginRight: "10px", color: "#fff" }}
          className="material-symbols-outlined"
        >
          share
        </span>
        <Text className="share_schedule" alignItems="center" color={"#fff"}>
          Share{" "}
          <span style={{ color: "#fff" }} className="share_schedule_schedule">
            Schedule
          </span>
        </Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="sharescheduleheading__text">
            Feel free to share the event details with your friends and family
            members
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box className="qrcodebox">
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  fontStyle: "italic",

                  textAlign: "center",
                }}
              >
                Scan and Download PDF
              </Text>
              <br />
              <Box style={{ width: "60%", margin: "auto" }}>
                <QRCode ref={qrCodeRef} value={qrLinksCode} marginLeft="30px" />
              </Box>
              <br />
            </Box>

            <ul class="wrapper">
              <li class="icon youtube">
                <span class="tooltip">Share on Whatsapp</span>
                <span>
                  <WhatsappShareButton
                    onClick={handleWhatsAppShare}
                    url={qrLinksCode}
                    title={WHATSAPP_MESSAGE_FILE_SHARING}
                    separator={WHATSAPP_MESSAGE_FILE_SHARING_SEPERATOR}
                  >
                    <Image src={whatsapp} width={"60px"} />
                  </WhatsappShareButton>
                </span>
              </li>
            </ul>
          </ModalBody>

          <ModalFooter>
            <Button
              style={{ backgroundColor: "rgba(166,124,0,1)", color: "#fff" }}
              mr={3}
              onClick={handleDownload}
            >
              Download
            </Button>
            <Button
              style={{ backgroundColor: "rgba(166,124,0,1)", color: "#fff" }}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default ShareSchedule;
