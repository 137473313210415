import React from "react";
import "../../Styles/About/index.css";
import { Box, Text } from "@chakra-ui/react";
const AboutLandscape = () => {
  return (
    <div id="about">
      <Box className="aboutLandscapeMain">
        <Box className="aboutLandscapeContext">
          <Text className="aboutLandscapeContextText">
            ABOUT
            <br /> THE
            <br /> EVENT
          </Text>
        </Box>
        <Box className="aboutLandscapeContent">
          <Text className="aboutLandscapeContentText">
            Once a year during winters, the sands around Jaisalmer come alive
            with the brilliant colors, music and laughter of the Desert
            Festival. Dressed in brilliantly hued costumes, the people of the
            desert dance and sing haunting ballads of valor, romance and
            tragedy. The fair has snake charmers, puppeteers, acrobats and folk
            performers. Camels, of course, play a stellar role in this festival,
            where the rich and colorful folk culture of Rajasthan is on show.
            <br />
            Jaisalmer Desert Festival is all about fun and excitement. One can
            enjoy vibrant puppet shows, folk music and dance, camel races, camel
            polo matches, tug of war, juggling events, turban tying and longest
            mustache competition. Participants wear colorful traditional attires
            and display the best of Rajasthani folk culture and traditions.
            Presentation of Gair dance forms and folk fire dances are an added
            attraction of Jaisalmer Desert festival. There is a unique
            competition for men by the name Maru Sri or Mr. Desert Pageant,
            which attracts locals as well as international travelers. There are
            plenty of stalls selling handicrafts and local knick-knacks and many
            food stalls serving the best of authentic local food.
            <br />
            The grand finale is a trip to the Sam sand dunes on the moonlight
            night, where one can also experience the pleasure of a camel ride.
            The folk artistes performing on the sand dunes take the enhancement
            to its crescendo.
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default AboutLandscape;
