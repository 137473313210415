import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Navbar from "../Components/Navbar";
import "../Styles/About/index.css";
import Footer from "../Components/Footer";
import AboutLandscape from "../Components/About/AboutLandscape";
import { useLocation } from "react-router-dom";
const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Navbar showNavs={false} />
      <Box className="About_Pages">
        <AboutLandscape />
      </Box>

      <Footer />
    </div>
  );
};

export default About;
