import React, { useState, useEffect } from "react";
import "../Styles/Navbar.css"; // Import CSS file for styling
import { Image } from "@chakra-ui/react";
import Logo from "../Images/Logo.png";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = ({ showNavs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <nav className={`navbar ${scrollPosition > 600 ? "scrolled" : ""}`}>
        <div className="logo">
          <Image className="logo" src={Logo} />
        </div>
        <button
          className="menu-toggle"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`menu ${isOpen ? "open" : ""}`}>
          <li>
            {showNavs ? (
              <>
                <Link to="/" className="Navbar_Tags" onClick={closeMenu}>
                  <AnchorLink href="#home" offset="100">
                    Home
                    <div className="line"></div>
                  </AnchorLink>
                </Link>
              </>
            ) : (
              <Link to="/" className="Navbar_Tags" onClick={closeMenu}>
                Home
                <div className="line"></div>
              </Link>
            )}
          </li>
          {showNavs && (
            <>
              <li>
                <AnchorLink
                  href="#schedule"
                  className="Navbar_Tags"
                  onClick={closeMenu}
                  offset="100"
                >
                  Schedule
                  <div className="line"></div>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="#places"
                  className="Navbar_Tags"
                  onClick={closeMenu}
                  offset="100"
                >
                  Places to Visit
                  <div className="line"></div>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="#gallery"
                  className="Navbar_Tags"
                  onClick={closeMenu}
                  offset="100"
                >
                  Gallery
                  <div className="line"></div>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="#howtoreach"
                  className="Navbar_Tags"
                  onClick={closeMenu}
                  offset="100"
                >
                  How to Reach
                  <div className="line"></div>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="#contactus"
                  className="Navbar_Tags"
                  onClick={closeMenu}
                  offset="100"
                >
                  Contact Us
                  <div className="line"></div>
                </AnchorLink>
              </li>
            </>
          )}
          {showNavs ? (
            <li>
              <Link to="/about" className="Navbar_Tags" onClick={closeMenu}>
                About
                <div className="line"></div>
              </Link>
            </li>
          ) : (
            <li>
              <Link to="/about" className="Navbar_Tags" onClick={closeMenu}>
                <AnchorLink href="#about" offset="100">
                  About
                  <div className="line"></div>
                </AnchorLink>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
