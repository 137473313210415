import React from "react";
import { Box, Text } from "@chakra-ui/react";
import "../../Styles/HowToReachContent/RoadContent.css";
const RoadContent = () => {
  return (
    <div className="background_Image_Content_Road">
      <Box className="RoadContentFlex">
        <Box className="RoadTextByRoad">
          <Text className="roadtextbyroad_text">By Road</Text>
        </Box>

        <Box className="RoadContentRight">
          <Text className="roadcontentright_text">
            Buses operate from Jaisalmer to Jodhpur, Jaipur, Ajmer, Bikaner,
            Udaipur, Mount Abu, Barmer, Ahmedabad, and Delhi.
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default RoadContent;
