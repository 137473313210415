import React from "react";
import { Box, Text } from "@chakra-ui/react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import "../Styles/Schedule.css";
import {
  Start_date,
  color_Selected,
  end_date,
  middle1_date,
  middle_date,
} from "../Constant/String";
import Start from "./FestivalSchedule/Start";
import Middle from "./FestivalSchedule/Middle";
import End from "./FestivalSchedule/End";
import LastDay from "./FestivalSchedule/LastDay";

import ShareSchedule from "./FestivalSchedule/ShareSchedule";

const Schedule = () => {
  return (
    <div id="schedule">
      <Box className="mainBox_Schedule">
        <Box className="Festival_Schedule">
          <Text className="festival_Schedule_text">Festival Schedule</Text>

          <ShareSchedule />
        </Box>

        <Box className="Tabs">
          <Tabs
            variant="soft-rounded"
            position="relative"
            colorScheme="green"
            className="tabs"
          >
            <TabList>
              <Tab
                _selected={{ color: "white", bg: color_Selected }}
                className="Tab_Text"

                // Add onClick handler to each tab
              >
                <h1 className="tablist__date">{Start_date}</h1>
              </Tab>

              <Tab
                _selected={{ color: "white", bg: color_Selected }}
                className="Tab_Text"
                // Add onClick handler to each tab
              >
                <h1 className="tablist__date">{middle_date}</h1>
              </Tab>
              <Tab
                _selected={{ color: "white", bg: color_Selected }}
                className="Tab_Text"
                // Add onClick handler to each tab
              >
                <h1 className="tablist__date">{middle1_date}</h1>
              </Tab>
              <Tab
                _selected={{ color: "white", bg: color_Selected }}
                className="Tab_Text"
                // Add onClick handler to each tab
              >
                <h1 className="tablist__date">{end_date}</h1>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Start />
              </TabPanel>
              <TabPanel>
                <Middle />
              </TabPanel>
              <TabPanel>
                <End />
              </TabPanel>
              <TabPanel>
                <LastDay />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </div>
  );
};

export default Schedule;
