import React, { useState, useEffect } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import "../Styles/LandScape.css";
import WatchVideo from "../Images/Watch video.png";

import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
    maxHeight: "90%",
    padding: 0,
  },
};

const LandScape = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const openYoutube = () => {
    setModalIsOpen(true);
  };

  const closeYoutube = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="home">
      <Box className="LandScapeContentWrapper">
        <Box className="LandScape_Bigger_Box">
          <Text className="LANDSCAPE_TEXT_BIGGER">
            JAISALMER DESERT <br /> FESTIVAL 2024
          </Text>
        </Box>

        <Box className="LandScape_Golden_Short_Box">
          <Text className="LandScape_Golden_Short_Text ">
            Golden Sand & Joyful Celebrations
          </Text>
        </Box>
        <Box className="watch_video_image">
          <p style={{ cursor: "pointer" }} onClick={openYoutube}>
            <Image src={WatchVideo} />
          </p>
        </Box>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeYoutube}
          style={customStyles}
          contentLabel="YouTube Video"
        >
          <button
            onClick={closeYoutube}
            style={{
              position: "absolute",
              top: windowWidth > 1024 ? "20px" : "10px",
              right: 0,
              margin: "10px",
              color: "rgba(166,124,0,1)",
              fontWeight: "900",
            }}
          >
            <span class="material-symbols-outlined">close</span>
          </button>
          <iframe
            width={windowWidth > 1024 ? "632px" : "290px"}
            height={windowWidth > 1024 ? "516px" : "180px"}
            src="https://www.youtube.com/embed/lFcPtyoicxo?si=2h2IV3rYjNLimL3H"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </Modal>
      </Box>
    </div>
  );
};

export default LandScape;
