import { placetovisit } from "../Static/Placetovisit.data";
import PlacetovisitCard from "./PlaceToVisit/PlacetovisitCard";
import { Box, Text } from "@chakra-ui/react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

export default function PlaceToVisit() {
  return (
    <>
      <div id="places">
        <Box className="placevisit_wrapper">
          <Box className="placetovisit__box">
            <Text className="placevisit__text">Places to Visit</Text>
          </Box>

          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {placetovisit.map((place, index) => (
              <SwiperSlide
                key={index}
                height={"6xl"}
                position="relative"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`url(${place.img})`}
              >
                <PlacetovisitCard {...place} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </div>
    </>
  );
}
