import React from "react";
import { Box, Text } from "@chakra-ui/react";
import "../../Styles/PlaceToVisit.css";
function PlacetovisitCard({ id, city, img, img1, description, geolocation }) {
  const handleMaps = async (geolocation) => {
    const longitude = geolocation[1];
    const latitude = geolocation[0];
    try {
      const mapsUrl = `https://maps.google.com/maps/?q=${latitude},${longitude}`;
      window.open(mapsUrl, "_blank");
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <Box
        key={id}
        className="placetovisit"
        style={{ backgroundImage: `url(${img})` }}
        _hover={{
          backgroundImage: `url(${img1})`,
          cursor: "pointer",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust opacity as needed
          }}
        >
          <Box className="placetovisit__city">
            <Text className="placetovisit__city__text">{city}</Text>
          </Box>
          <Box className="placetovisit_svg">
            <svg
              viewBox="0 0 533 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M532.707 8.70711C533.098 8.31658 533.098 7.68342 532.707 7.29289L526.343 0.928932C525.953 0.538408 525.319 0.538408 524.929 0.928932C524.538 1.31946 524.538 1.95262 524.929 2.34315L530.586 8L524.929 13.6569C524.538 14.0474 524.538 14.6805 524.929 15.0711C525.319 15.4616 525.953 15.4616 526.343 15.0711L532.707 8.70711ZM0 9H532V7H0V9Z"
                fill="white"
              />
            </svg>
          </Box>
          <Box className="placetovisit__description">
            <Text className="placetovisit__description__text">
              {description}
            </Text>
          </Box>
          <Box
            className="placetovisit__location"
            onClick={() => handleMaps(geolocation)}
          >
            <Text
              style={{
                fontSize: "17px",
                color: "#fff",
                textShadow: "2px 2px 2px rgba(0, 0, 0, 0.9)",
                lineHeight: "23px",
                fontWeight: "450",
              }}
            >
              View Location
            </Text>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 4.5V6.5H16.09L4.5 18.09L5.91 19.5L17.5 7.91V14.5H19.5V4.5H9.5Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default PlacetovisitCard;
