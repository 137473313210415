import Arti from "../Images/JestivalArtiSchedul.jpeg";

import sadho from "../Images/SadhoBand.jpeg";
import kite from "../Images/kiteFestival.jpeg";
import gadisar from "../Images/gadsisar-sagar-lake.jpg";
import laxmi from "../Images/ShriLaxmiNath.jpeg";

import balloon from "../Images/Balloon.jpeg";
import camelRace from "../Images/CamelRace.jpeg";
import Dine from "../Images/Dine.jpeg";

import camelRace1 from "../Images/camelRace.jpg";
import Pollo from "../Images/Pollo.jpeg";

import khaba from "../Images/khaba (1).jpg";

import moustache from "../Images/Moustache competition.jpeg";
import chakriDance from "../Images/chakriDance.jpg";
import rangoli from "../Images/rangoli.jpg";
import camelSafari from "../Images/camelSafari.jpg";
import mameKhan from "../Images/mameKhan.jpg";
import khajikhan from "../Images/GajiKhan.jpeg";
import mrDesert from "../Images/mrDesert.jpg";
import anwarKhan from "../Images/anwarKhan.jpg";
import kal from "../Images/Kalbelia-Dance-Rajasthan.jpg";
import tagaRam from "../Images/tagaRam.jpg";
import shobhYatra from "../Images/shobhaYatra.jpg";
//!  fix img data to pick from downloaded files only
export const FirstDateData = [
  {
    time: "9.30 am - 10.00 am",
    programme: ["- Aarti"],
    venue: "Venue: Nepaleshwar Mahadev Temple, Pokhran",
    img: Arti,
    location: [26.92146695320349, 71.91419372538579],
  },
  {
    time: "10.00 am - 11.00 am",
    programme: ["- Shobha Yatra"],
    venue: "Venue: Salam Sagar Talab to Govt. Sr. Sec. School, Pokhran",
    img: shobhYatra,
    location: [26.921184616445878, 71.91382069749307],
  },
  {
    time: "11.00 am - 12.00 pm",
    programme: ["- Cultural Programme by Folk Artists"],
    venue: "Venue: Govt. Sr. Sec. School, Pokhran",
    img: kal,
    location: [26.91204456751731, 71.92794788271893],
  },
  {
    time: "12.00 pm - 3.00 pm",
    programme: [
      "- Turban Tying Competition",
      "- Moustache Competition",
      "- Miss Pokhran Competition",
      "- Mr. Pokhran Competition",
    ],
    venue: "Venue: Govt. Sr. Sec. School, Pokhran",
    img: moustache,
    location: [26.91204456751731, 71.92794788271893],
  },
  {
    time: "6.00 pm - 9.30 pm",
    programme: [
      "- Sadho Band",
      "- Swati Mishra (Ram Aayenge)",
      "- Terahtali Dance",
      "- Baba Ramdeo Bhajan By Folk Artists",
    ],
    venue: "Venue: Loharki Village",
    img: sadho,
    location: [27.140566365128556, 71.77197757298204],
  },
];

export const SecondDateData = [
  {
    time: "8.30 am - 9.30 am",
    programme: ["- Aarti"],
    venue: "Venue: Shri Laxminath Ji Temple, Fort, Jaisalmer",
    img: laxmi,
    location: [26.912566694448238, 70.91196937668667],
  },

  {
    time: "9.30 am - 10.30 am",
    programme: ["- Shobha Yatra"],
    venue: "Venue: Gadisar Lake to Shahid Poonam Stadium Via Fort, Jaisalmer",
    img: gadisar,
    location: [26.9098114513919, 70.92036388172943],
  },
  {
    time: "10.00 am onwards",
    programme: [
      "- Art and craft bazar, Kite show",
      "- Exhibition of ICH based tourism product from Patodi, Salawas, Pokhran",
      "- Feast of the desert (Food festival)",
      "- Live preparation of local cuisines",
      "- Exhibition of innovative food products",
      "- Local food competition",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer",
    img: kite,
    location: [26.913673677413545, 70.90667776636396],
  },
  {
    time: "11.00 am - 12.00 pm",
    programme: [
      "- Inauguration of Desert Festival",
      "- Golden skies (Releasing golden balloons)",
      "- Cultural performance",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer",
    img: balloon,
    location: [26.913673677413545, 70.90667776636396],
  },

  {
    time: "12.00 pm - 2.00 pm",
    programme: [
      "- Turban tying competition",
      "- Moomal Mahendra competition",
      "- Moustache competition",
      "- Miss Moomal competition",
      "- Mrs Desert competition",
      "- Mr. Desert competition",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer ",
    img: mrDesert,
    location: [26.913673677413545, 70.90667776636396],
  },
  {
    time: "2.00 pm - 3.30 pm",
    programme: [
      "- Dine with Jaisalmer (Hosted by I Love Jaisalmer Foundation)",
    ],
    venue: "Venue: Entire City, Jaisalmer ",
    img: Dine,
    location: [26.91362584334429, 70.90664558079116],
  },
  {
    time: "6.30 pm - 7.00 pm",
    programme: [
      "- Icons of jaisalmer (Felicitation ceremony)",
      "- Padamshree Anwar Khan Baiyan",
      "- Shri Pepe Khan",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer",
    img: anwarKhan,
    location: [26.913673677413545, 70.90667776636396],
  },
  {
    time: "7.00 pm - 10.00 pm",
    programme: [
      "- Performance by Shri Pepe Khan",
      "- Performance by Padamshree Anwar Khan Baiyan",
      "- Performance by Mere Ram Aayenge fame Swati Mishra",
      "- Performance by local Bollywood celebrity band",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer",
    img: mameKhan,
    location: [26.913673677413545, 70.90667776636396],
  },
];
//! Check img data
export const ThirdDateData = [
  {
    time: "6.00 am - 8.00 am",
    programme: ["- Instrument Music & Yoga"],
    venue: "Venue: Gadisar Lake, Jaisalmer",
    img: "https://jaisalmerdesertfestival.rajasthan.gov.in/img/visit/2.png",
    location: [26.909768443433343, 70.9203636237239],
  },
  {
    time: "10.00 am - 1.30 pm",
    programme: [
      "- Camel Decoration Competition",
      "- Shan-E-Marudhara Competition",
      "- Air warrior Drill Show by I.A.F",
      "- Tug of War competition for Men & Women",
      "- Panihari Matka Race Competition for Women",
      "- Kabaddi match",
    ],

    venue: "Venue: Dedansar Stadium, Jaisalmer",
    img: camelRace,
    location: [26.932490355060207, 70.91378284704882],
  },
  {
    time: "4.00 pm - 6.00 pm",
    programme: [
      "- Camel Tattoo Show (Acrobatics) by B.S.F",
      "- Camel Polo Match",
    ],
    venue: "Venue: Dedansar Stadium, Jaisalmer",
    img: Pollo,
    location: [26.932633832790593, 70.91383649217177],
  },
  {
    time: "6.00 pm - 6.30 pm",
    programme: [
      "- Icons of Jaisalmer (Felicitation Ceremony)",
      "- Shri Gaji Khan Barna",
      "- Dr. Aidan Singh Bhati (Historian)",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer",
    img: khajikhan,
    location: [26.91362584334429, 70.90664558079116],
  },
  {
    time: "7.00 pm - 10.00 pm",
    programme: [
      "- Ghutna Chakri Dance",
      "- Kamaycha charm by Ghewar khan (Legacy of Padam shree Sakar Khan)",
      "- Fusion show by Desert Symphony Band (Shri Gaji Khan Barna)",
      "- Celebrity mega night",
    ],
    venue: "Venue: Shahid Poonam Singh Stadium, Jaisalmer",
    img: chakriDance,
    location: [26.91362584334429, 70.90664558079116],
  },
];

export const FourthDayData = [
  {
    time: "7.30 am onwards",
    programme: ["- Peacock sighting and live instrumental music performances"],
    venue: "Venue: Khaba Fort ",
    img: khaba,
    location: [26.811541129323693, 70.67337973752467],
  },
  {
    time: "10.00 am onwards",
    programme: ["- Rangoli, Mandana and Wall painting"],
    venue: " Venue: Kuldhara Deserted Village",
    img: rangoli,
    location: [26.8751249566571, 70.781177139943],
  },
  {
    time: "12.00 pm - 4.00 pm ",
    programme: ["- Horse Race Show"],
    venue: "Venue: Rann of Lanela",
    img: "https://rajasthantourismbuzz.files.wordpress.com/2014/10/marwar-horse.jpg",
    location: [27.07363906931694, 70.77631686869837],
  },
  {
    time: "12.00 pm onwards",
    programme: [
      "- Camel safari and Live folk musical show (without any sound amplification)",
    ],
    venue: "Venue: Near Khuri Village (Outside DNP Area)",
    img: camelSafari,
    location: [26.599636649899566, 70.7356170605463],
  },
  {
    time: "2.30 pm - 5.00 pm",
    programme: ["- Camel dance", "- Camel race", "- Camel tattoo show"],
    venue: "Venue: Near Khuri Village (Outside DNP Area)",
    img: camelRace1,
    location: [26.599636649899566, 70.7356170605463],
  },

  // {
  //   time: "5.30 pm - 6.00 pm",
  //   programme: ["- Camel Race", "- Icons of Jaisalmer (Felicitation ceremony)"],
  //   venue: "Venue: Near Sam(outside DNP Area)",
  //   img: jaisalmericons,
  //   location: [26.62358416459515, 70.70659115211787],
  // },
  {
    time: "6.00pm - 6.30pm",
    programme: [
      "- Shri Nand Kishore Sharma (Desert Cultural Museum)",
      "- Shri Laxmi Narayan Khatri (Thar Heritage Museum)",
      "- Shri Chatar Singh Ramgarh (Water Body Conservationist)",
      "- Shri Parth Jagani (Environmentalist)",
    ],
    venue: "Venue: Near Sam (outside DNP Area)",
    img: "https://images.pexels.com/photos/12912712/pexels-photo-12912712.jpeg?auto=compress&cs=tinysrgb&w=600",
    location: [26.882421939619476, 70.53272985175208],
  },

  {
    time: "6.30 pm - 10.00 pm",
    programme: [
      "- Performance by Shri Tagaram Bheel",
      "- World Record Kalbelia Performace With Gulabo Devi ",
      "- Celebrity Performance",
    ],
    venue: "Venue: Near Sam (outside DNP Area)",
    img: tagaRam,
    location: [26.882421939619476, 70.53272985175208],
  },
];
export const sharepdflink =
  "https://drive.google.com/file/d/1eqwJwjm2NBrE6-6h6ZSaWZk48fAwljcE/view";
