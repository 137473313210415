export const Start_date = "21/02/2024";
export const middle_date = "22/02/2024";
export const middle1_date = "23/02/2024";
export const end_date = "24/02/2024";
export const color_Selected = "rgba(166,124,0,1)";
export const color_NonSelected = "rgba(rgba(221,212,179,1))";
export const Arti_Time = "8.30-9.30 AM";
export const SCHDULE_ARTI_FORT = "Shri Laxmi Ji Temple,Fort";
export const SCHUDLE_EVENT_ARTI = "Arti";
export const Location = "View Location";
export const WHATSAPP_MESSAGE_FILE_SHARING =
  "Hey there! 🌟 Join us at the Jaisalmer Desert Festival 2024! 🎉.";
export const WHATSAPP_MESSAGE_FILE_SHARING_SEPERATOR =
  "Don't miss out on the incredible festivities! Click the link below to check out the event schedule 🐪🎶🏜️";

export const YOUTUBE_VIDEO_LINKS =
  "https://youtu.be/lFcPtyoicxo?si=VYlg92RwFwK4f0Sr";

export const PLANE = "By Plane";
export const ROAD = "By Road";
export const Train = "By Train";
export const PLANE_DESCRIPTION =
  "Jaisalmer has its own airport. Regular flights operate from all major Indian cities to Jaisalmer i.e. Ahmedabad, Dehli, Mumbai and Jaipur.";
export const TRAIN_DESCRIPTION =
  " Jaisalmer is connected to Jaipur, Jodhpur, Ajmer, and Delhi via broad gauge railway tracks.";
export const ROAD_DESCRIPTION =
  "Buses operate from Jaisalmer to Jodhpur, Jaipur, Ajmer, Bikaner,Udaipur, Mount Abu, Barmer, Ahmedabad, and Delhi.";
//Ahmedabad spelling chnage
