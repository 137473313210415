import React from "react";
import { Box, Text } from "@chakra-ui/react";
import "../Styles/HelpLine.css";
const HelpLine = () => {
  return (
    <div id="contactus">
      <Box className="helpline__wrapper">
        <Box className="helpline__wrapper">
          <Box className="helpline_Box">
            <Text className="helpLine_Text">Helpline</Text>
          </Box>
          <Box className="helpline__content__box">
            <Box
              style={{ width: "90%", margin: "auto" }}
              className="helpline__box__contain1"
            >
              <Text className="helpline">Medical Emergency</Text>

              <br />

              <Text
                className="helpline__description"
                style={{ textAlign: "center", marginBottom: "15px" }}
              >
                Jawahar community health center
                <br /> Dial: 100 ,108
              </Text>
            </Box>

            <Box className="lineBreaker"></Box>

            <Box
              style={{ width: "90%", margin: "auto" }}
              className="helpline__box__contain1"
            >
              <Text className="helpline">Control Room</Text>

              <br />

              <Text
                className="helpline__description"
                style={{ textAlign: "center", marginBottom: "15px" }}
              >
                Tourist Reception Centre <br />
                Ph: 02992-252406
              </Text>
            </Box>

            <Box className="lineBreaker"></Box>

            <Box
              style={{ width: "90%", margin: "auto" }}
              className="helpline__box__contain1"
            >
              <Text
                className="helpline"
                style={{
                  marginTop: "38px",
                }}
              >
                Nodal Office
              </Text>
              <br />
              <Text
                className="helpline__description"
                style={{ textAlign: "center", marginBottom: "15px" }}
              >
                Khemendra Singh <br />
                Ph: 9414601540 <br />
                Email: trcjaisal2992@gmail.com
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default HelpLine;
