import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "@chakra-ui/react";

import "swiper/css";
import "../../Styles/IncredibleJaislmer.css";
import "swiper/css/navigation";

import Katputli from "../../Images/Katputl.jpeg";

import murti from "../../Images/murti.jpg";

import fort2 from "../../Images/fort2 (1).jpg";

import jaisalmer1 from "../../Images/jaisalmerextra1 (1).jpg";
import wide from "../../Images/wideAngle.jpg";
import jaisalmer3 from "../../Images/jaisalmerextra3 (1).jpg";
import bird from "../../Images/bird.jpg";
import city from "../../Images/city.jpg";
import jaisalmer5 from "../../Images/jaisalmerextra4 (1).jpg";
import gadisar2 from "../../Images/Gadisar2.jpg";
import { Navigation } from "swiper/modules";

export default function Carousel() {
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [isTouched, setIsTouched] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleTouchStart = (e) => {
    // e.preventDefault()
    console.log("START");
    setIsTouched(true);
  };

  const handleTouchCancel = (e) => {
    console.log("Cancel");
    e.preventDefault();
    setIsTouched(false);
  };
  const handleTouchEnd = (e) => {
    console.log("End");

    setIsTouched(false);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const Images = [
    { img: gadisar2, alt: "gadisar2" },
    { img: wide, alt: "WideAngle" },
    { img: fort2, alt: "Pooja" },
    { img: city, alt: "city" },
    { img: murti, alt: "murti" },
    { img: jaisalmer1, alt: "murti" },

    { img: jaisalmer3, alt: "murti" },
    { img: Katputli, alt: "Katputli" },
    { img: jaisalmer5, alt: "murti" },
    { img: bird, alt: "bird" },
  ];

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 1280 && width > 767) {
        setSlidesPerView(2);
      } else if (width <= 767) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(4);
      }
    }

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //carousel hovering effect
  return (
    <>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={19}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {Images &&
          Images.map((el, index) => {
            return (
              <SwiperSlide className="incredible_hover_images" key={index}>
                <Image
                  src={el.img}
                  className={`image_hover_effect ${isHovered || isTouched ? "hovered" : ""}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={(e) => handleTouchStart(e)}
                  onTouchEnd={(e) => handleTouchEnd(e)}
                  onTouchCancel={(e) => handleTouchCancel(e)}
                  width={"100%"}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
}
