import "./App.css";
import AllRoute from "./Routes/AllRoute";

function App() {
  // Automatically scrolls to top whenever pathname changes

  return (
    <div className="App">
      <AllRoute />
    </div>
  );
}
export default App;
