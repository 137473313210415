import React from "react";
import "../Styles/HowToReach.css"; // Import CSS for styling

import RoadContent from "./HowToReachContent/RoadContent";
import TrainContent from "./HowToReachContent/TrainContent";
import PlaneContent from "./HowToReachContent/PlaneContent";
import { Box, Text } from "@chakra-ui/react";

const HelpToReach = () => {
  return (
    <Box id="howtoreach">
      <Box className="howtoreach__wrapper">
        <Box className="howtoreach__box">
          <Text className="JaisalmerHowToReach">
            How to reach{" "}
            <span className="jaisalmerhowtoreach__span">Jaisalmer</span>
          </Text>
        </Box>

        <div className="HowToReach">
          <PlaneContent />
          <TrainContent />
          <RoadContent />
        </div>
      </Box>
    </Box>
  );
};

export default HelpToReach;
