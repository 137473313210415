import React from "react";
import { Box, Text } from "@chakra-ui/react";
import "../../Styles/HowToReachContent/TrainContent.css";
import { TRAIN_DESCRIPTION, Train } from "../../Constant/String";

const TrainContent = () => {
  return (
    <div className="background__color__train">
      <Box className="trainBox">
        <Box className="trainBox__Content">
          <Text className="trainBox__ContentText">{Train}</Text>
        </Box>
        <Box className="trainBox__Description">
          <Text className="trainBox__DescriptionText">{TRAIN_DESCRIPTION}</Text>
        </Box>
      </Box>
    </div>
  );
};

export default TrainContent;
