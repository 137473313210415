import React from "react";
import "../Styles/IncredibleJaislmer.css";
import { Box, Text } from "@chakra-ui/react";

import Carousel from "./IncredibleJaismerCarousel/Carousel";

const IncredibleJaislmer = () => {
  return (
    <div id="gallery">
      <Box className="incredible_jaisalmer_wrapper">
        <Box className="Incredible_Jaisalmer">
          <Text className="Incredible_jaisalmer_Text">
            Incredible Jaisalmer
          </Text>
        </Box>

        <Box className="incrediblejaisalmer__carousel">
          <Carousel />
        </Box>
      </Box>
    </div>
  );
};

export default IncredibleJaislmer;
