import React from "react";
import "../Styles/Footer.css";
import { Box, Image } from "@chakra-ui/react";
import instagram from "../Images/Instagram.png";
import twitter from "../Images/twitter.png";

import LOGO3 from "../Images/LOGO3.png";
import LOGO2 from "../Images/LOGO2.png";

function openTwitter(url) {
  window.open(url, "_blank");
}

const Footer = () => {
  return (
    <div id="contact">
      <Box className="FooterSection">
        <Box className="Logo_Section_Footer">
          <Box
            className="LOGO_IMAGES_BOX"
            onClick={() => openTwitter("https://Jaisalmer.rajasthan.gov.in")}
          >
            <Image src={LOGO3} className="LOGOS_IMAGES_FOOTER" />
          </Box>
          <Box
            className="LOGO_IMAGES_BOX"
            onClick={() => openTwitter("https://www.tourism.rajasthan.gov.in/")}
          >
            <Image src={LOGO2} className="LOGOS_IMAGES_FOOTER" />
          </Box>
        </Box>
        <Box className="SocialMedia">
          <Box className="SocialMediaIcons">\
            <Box>
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openTwitter(
                    "https://www.instagram.com/jaisalmerdesert.festival/?igsh=Nnl6aDR2YW11bGlk"
                  )
                }
                target="_blank"
              >
                <Image src={instagram} className="social_media_images" />
              </p>
            </Box>
            <Box>
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openTwitter("https://twitter.com/DesertFest2024")
                }
              >
                <Image src={twitter} className="social_media_images" />
              </p>
            </Box>
          </Box>
          <Box
            className="goverment_id_link"
            onClick={() => openTwitter("https://Jaisalmer.rajasthan.gov.in")}
          >
            <p className="govermnet_Tag">jaisalmer.rajasthan.gov.in</p>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
