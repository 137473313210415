"use client";

import { Flex, Box, Image, useColorModeValue, Text } from "@chakra-ui/react";
import { useState } from "react";
import "../../Styles/Schedule.css";
import locationimg from "../../Images/Location.png";

function ScheduleCard({ time, programme, venue, img, location }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleHover = () => {
    setIsHovering(true);
  };

  const handleLeave = () => {
    setIsHovering(false);
  };

  const openMaps = (latitude, longitude) => {
    try {
      const mapsUrl = `https://maps.google.com/maps/?q=${latitude},${longitude}`;
      window.open(mapsUrl, "_blank");
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="center"
      className="schedulecard__flex"
    >
      <Box
        bg={useColorModeValue("white", "#fff")}
        maxW="lg"
        rounded="lg"
        position="relative"
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        style={{
          width: "350px",
          height: "420px",
        }}
      >
        <Image
          src={img}
          alt={`Picture of ${time}`}
          roundedTop="lg"
          style={{
            transition: "border-radius 1.3s ease, background-color 0.3s ease",
            borderRadius: !isHovering ? "73px 0px 73px 0px" : "",
            height: !isHovering ? "220px" : "415px",
            width: "100%",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "20px",
            left: "86%",
            height: "40px",
            width: "40px",
            zIndex: 2,
            cursor: "pointer",

            // Ensure this image is below the first image
          }}
          onClick={() => openMaps(location[0], location[1])}
        >
          <Image
            width={"40px"}
            height={"40px"}
            src={locationimg}
            alt={`Picture of ${time}`}
            roundedTop="lg"
          />
        </button>

        <Box
          p="2"
          style={{
            position: "absolute",

            zIndex: "999",
            opacity: !isHovering ? "1" : ".6",
            top: !isHovering ? "52%" : "63%",
            width: "100%",
            height: !isHovering ? "200px" : "150px",
            // Synchronized delay
            transition: "border-radius 1.3s ease, background-color 0.3s ease",
            borderRadius: !isHovering ? "0px 53px 0px 53px" : "",
            backgroundColor: !isHovering
              ? "rgba(159, 119, 0, 1)"
              : "rgba(0, 0, 0, 1)",
          }}
        >
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box
              fontSize="xl"
              fontWeight="500"
              as="h4"
              lineHeight="tight"
              isTruncated
              color={"#fff"}
              textAlign={"center"}
              style={{
                marginLeft: !isHovering ? "20px" : "0px",
              }}
            >
              <Text
                style={{
                  fontWeight: !isHovering ? "1200" : "450 !important",
                  zIndex: "2",
                  opacity: "1 !important",
                  textAlign: !isHovering ? "center" : "left",
                  fontSize: !isHovering ? "14px" : "16px",
                }}
              >
                {time}
              </Text>
            </Box>
          </Flex>
          {!isHovering && (
            <Box
              style={{
                marginLeft: !isHovering ? "20px" : "0px",
              }}
            >
              <Box>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[0]}
                </Text>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[1]}
                </Text>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[2]}
                </Text>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[3]}
                </Text>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[4]}
                </Text>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[5]}
                </Text>
                <Text
                  color={"#fff"}
                  style={{
                    fontWeight: "450",
                    zIndex: 999,
                    fontSize: !isHovering ? "14px" : "16px",
                  }}
                >
                  {programme[6]}
                </Text>
              </Box>
            </Box>
          )}

          <Box
            // fontSize="xl"
            // color={useColorModeValue("#fff", "#fff")}
            // style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
            style={{
              marginLeft: !isHovering ? "20px" : "0px",
            }}
          >
            <Text
              color={"#fff"}
              style={{
                fontWeight: "450",
                zIndex: 999,
                fontSize: !isHovering ? "14px" : "16px",
              }}
            >
              {venue}
            </Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default ScheduleCard;
