import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import "../../Styles/Schedule/Front.css";
import { FourthDayData } from "../../Static/Schedule.data";
import ScheduleCard from "../Common/ScheduleCard";
import "../../Styles/Schedule/Front.css";
export default function LastDay() {
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 1280 && width > 767) {
        setSlidesPerView(2);
      } else if (width <= 767) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(4);
      }
    }

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={19}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
    >
      {FourthDayData.map((el, index) => (
        <SwiperSlide key={index}>
          <ScheduleCard {...el} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
