import React from "react";
// import Navbar from "./Components/Navbar";
import { Box } from "@chakra-ui/react";
import LandScape from "../Components/LandScape";
import Footer from "../Components/Footer";
import HelpLine from "../Components/HelpLine";
import HowToReach from "../Components/HowToReach";
import IncredibleJaislmer from "../Components/IncredibleJaislmer";
import PlaceToVisit from "../Components/PlaceToVisit";
import Schedule from "../Components/Schedule";
import Navbar from "../Components/Navbar";
import "../Styles/Home.css";
const Home = () => {
  return (
    <div>
      <Navbar showNavs={true} />

      <Box className="LandScape_Background_Image">
        <LandScape />
      </Box>

      <Schedule />

      <PlaceToVisit />

      <IncredibleJaislmer />

      <HowToReach />

      <HelpLine />

      <Footer />
    </div>
  );
};

export default Home;
