import khuldera from "../Images/khuldharavillag.jpeg";
import kabha from "../Images/KabhaFort.jpg";
import sam from "../Images/sam&dunes.jpg";
import haveli2 from "../Images/haveli2 (1).jpg";
import sam2 from "../Images/sam&dunes.jpg";
import jaisalmer from "../Images/jaisalmerFort.jpg";
import gadisarLake from "../Images/gadsisar-sagar-lake.jpg";
import jaisalmerFort1 from "../Images/jaisalmerfort1 (1).jpg";
import gadisarlake1 from "../Images/gadisarlake1.jpg";
export const placetovisit = [
  {
    id: 1,
    city: "Gadisar Lake",
    img: gadisarLake,
    img1: gadisarlake1,
    description:
      "The history of this shimmering lake in Jaisalmer dates back to 1400 AD during Maharawal Gadsi Singh's rule. It is made as a conservation reservoir for rainwater harvesting during the erstwhile era and was once the prime source of water for the entire city. But now it offers peace and tranquility as you can paddleboat and rowboat the waters along with spotting the migratory birds. You can visit Gadisar Lake between 7 AM - 7 PM (approx.) and for boating, be prepared to pay 100 INR (approx.).",
    geolocation: [26.9098114513919, 70.92036388172943],
  },
  {
    id: 2,
    city: "Jaisalmer Fort",
    img: jaisalmer,
    img1: jaisalmerFort1,
    description:
      "Explore the Sonar Qila or Golden Fort on your Jaisalmer tour. Jaisalmer Fort is a famous architectural marvel not only in Jaisalmer but also in the entire Rajasthan and India. Tour the several astounding monuments inside the fort, from Raj Mahal, Laxminath Shrine to Merchant Havelis and the ornate Jain temples. ",
    geolocation: [26.91307732293745, 70.91233034639377],
  },

  {
    id: 3,
    city: "Patwon ki Haveli",
    img: "https://www.tourmyindia.com/images/patwaon-ki-haveli3.jpg",
    img1: haveli2,
    description:
      "As the name suggests, it is a haveli, formed by the cluster of 5 different Havelis within one single premise. Patwon Ki Haveli is one of the photogenic spots in Jaisalmer, which you should definitely explore. Get mesmerized by the intricate architecture work, various old items used by kings and family. You can click as many as the pictures inside the Haveli at an additional cost (camera charges). Foreigners must pay 100 INR (approx.) as an entrance fee, while the Indians need to pay 20 INR (approx.).",
    geolocation: [26.916431382527563, 70.91431298736705],
  },
  {
    id: 4,
    city: "Kuldhara Village",
    img: khuldera,
    img1: khuldera,
    description:
      "How about getting some spooky feel in your spine? If that sounds like what adventure means to you, visit Kuldhara Village. It is an abandoned village that shows no signs of human life some 300 years (approx.) ago! Kuldhara Village is a clan of many villages and was once the inhabited land of Paliwal Brahmins. You must learn the history of Kuldhara Village on a guided tour to know why the hundred of the family vanish overnight. Make sure you visit before sunrise because even Archeologists recommend the same.",
    geolocation: [26.80332243315976, 70.79758201137889],
  },
  {
    id: 5,
    city: "Khaba Fort",
    img: kabha,
    img1: "https://www.holidify.com/images/cmsuploads/compressed/Jaisalmer_Khaba_Fort_Main_20180221131838.jpg",
    description:
      "Discover Khaba Fort: an abandoned village of Paliwal Brahmins, deserted 200 years ago. Once a vital part of Kuldhara, it served as a frontier fort for Silk Route traders. Despite ongoing restoration, its roofless ruins and peacock-filled surroundings evoke an eerie charm..",
    geolocation: [26.812408161538812, 70.6732252591139],
  },
  {
    id: 6,
    city: "Sam Sand Dunes",
    img: sam,
    img1: sam2,
    description:
      "Sam Sand Dunes: Iconic desert destination in Jaisalmer, offering thrilling camel safaris, mesmerizing sunset views, and vibrant cultural festivals showcasing Rajasthani heritage.",
    geolocation: [26.838326155854258, 70.54300919515201],
  },
];
